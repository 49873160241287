import React, { useEffect, useRef, useState }  from "react";
import axios from "axios";
import {Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import FBook from "../images/QrCodeAIBFB.png"
import Insta from "../images/QrCodeAIBInsta.png"
import LogoMeilleurAgent from "./logoMeilleurAgent";
gsap.registerPlugin(ScrollTrigger);



const Texthomepage = () => {


    const flecheCarreRight = <FontAwesomeIcon icon={faCaretRight} className="couleurAIB" />

    const gsaptextAccueil1 = useRef(null);
    const gsaptextAccueil2 = useRef(null);
    const gsaptextAccueil3 = useRef(null);
    const gsaptextAccueil4 = useRef(null);
    const divaccueil = useRef(null);
   
    const durationAnim = 0.8;
    const [msgForTextHomePageMsg1, setmsgForTextHomePageMsg1] = useState("")
    const [msgForTextHomePageMsg2, setmsgForTextHomePageMsg2] = useState("")
    const [msgForTextHomePageMsg3, setmsgForTextHomePageMsg3] = useState("")
    const [msgForTextHomePageMsg4, setmsgForTextHomePageMsg4] = useState("")
    
    useEffect(() => {


        const fetchData = async () => {
            const result = await axios(
              'https://www.aibimmobilier.fr:444/texthomepage',
            );
      
            let dataFromDb = []
            
            result.data.map((item, index) => (
                dataFromDb.push(item)
            ));

            setmsgForTextHomePageMsg1(dataFromDb[0].msg1)
            setmsgForTextHomePageMsg2(dataFromDb[0].msg2)
            setmsgForTextHomePageMsg3(dataFromDb[0].msg3)
            setmsgForTextHomePageMsg4(dataFromDb[0].msg4)



            
            
            
          };
      
          fetchData();




        const t1 =gsap.timeline({
            
            scrollTrigger:{
                trigger:divaccueil.current,
                // id:'divaccueil',
            //    markers: true,
                // start: "top center",   //start scroller-start
                start: "top bottom-=250",   //start scroller-start
              //end: "bottom bottom",   //end scroller-end
               toggleActions: 'play reverse none none', // event => onEnter, onLeave, onEnterBack, and onLeaveBack, 
               
               
              },
        }); 


        
        
        t1.fromTo(gsaptextAccueil1.current, {
            y: -100,
            autoAlpha: 0,
      
        }, {
            duration: durationAnim,
            autoAlpha: 1,
            y: 0,
           
           
        },
     
        )
        t1.fromTo(gsaptextAccueil2.current, {
            y: -100,
            autoAlpha: 0,
      
        }, {
            duration: durationAnim,
            autoAlpha: 1,
            y: 0,
      
        }
        )
        t1.fromTo(gsaptextAccueil3.current, {
            y: -100,
            autoAlpha: 0,
      
        }, {
            duration: durationAnim,
            autoAlpha: 1,
            y: 0,
      
        }
        )
        t1.fromTo(gsaptextAccueil4.current, {
            y: -100,
            autoAlpha: 0,
      
        }, {
            duration: durationAnim,
            autoAlpha: 1,
            y: 0,
           
      
        }
        )


    }, []);
    
    return (


        // {msgForTextHomePage.map((item, index) => (
        //     <option key={index}>{item.dureeemprunt}</option>
        //   ))}
        <Container  className="containerTextHomepage">
            <div ref={divaccueil}  className="divaibblockTextHomepage " >
                
            <p ref={gsaptextAccueil1}  className="textAccueil1 colorAIB">
            <span dangerouslySetInnerHTML={{ __html: msgForTextHomePageMsg1   }} />


            
            {/* Aib Immobilier Votre Agence N°1 des Ventes sur Ablis<br/>
            De nombreuses ventes dans le 78660 et en Eure et Loir<br/>
            Alors n’hésitez plus si vous souhaitez VENDRE contactez nous */}
            </p>
            <p ref={gsaptextAccueil2}  className="textAccueil2 colorAIB">
            <span dangerouslySetInnerHTML={{ __html: msgForTextHomePageMsg2   }} />
            {/* Aib c’est une équipe professionnelle pour vendre dans les meilleures conditions. */}
            </p>
            <p ref={gsaptextAccueil3}  className="textAccueil3 colorAIB">
            <span dangerouslySetInnerHTML={{ __html: msgForTextHomePageMsg3   }} />
            {/* Votre Garantie c’est notre connaissance du secteur. */}
            </p>
            <p ref={gsaptextAccueil4} className="textAccueil4 colorAIB vertCenter">
                
            {/* <span dangerouslySetInnerHTML={{ __html: msgForTextHomePageMsg4   }} className="addmarginRight" /> */}
            <span dangerouslySetInnerHTML={{ __html: msgForTextHomePageMsg4   }} className=" addmarginRight" />
            <div className="containerImg">
            <a href="https://www.facebook.com/p/AIB-Immobilier-100063543912628/?locale=fr_FR" target="_blank" rel="noopener noreferrer"> 
            <img className=" posImg zoomEffect" src={FBook} alt="Facebook"/> 
            </a>
            
            <a href="https://www.instagram.com/aibimmobilier/" target="_blank" rel="noopener noreferrer"> 
            <img  className=" posImg zoomEffect" src={Insta} alt="Instagram"/>
            </a>
            <LogoMeilleurAgent/>
            </div>
            {/* Ventes Location Achats Investissement Défiscalisation (Loi Pinel)<br/>
Consultez nous */}
            </p>        

                
            </div>
        </Container>




    )
}

export default Texthomepage;